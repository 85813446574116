@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap);
.styles_wrapper__1fwL7 {
  margin: 0 30px;
  display: flex;
  grid-gap: 5rem;
  gap: 5rem;
}

.styles_buttonGroup__iowPN {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.styles_buttonGroup__iowPN > .styles_activeButton__3vVRt:not(:last-child),
.styles_buttonGroup__iowPN > .styles_button__16mel:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.styles_buttonGroup__iowPN > .styles_activeButton__3vVRt:not(:first-child),
.styles_buttonGroup__iowPN > .styles_button__16mel:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.styles_activeButton__3vVRt,
.styles_button__16mel {
  border: solid 1px rgb(233, 235, 237) !important;
  border-radius: 4px;
  color: rgb(47, 57, 65) !important;
  flex: 1 1 auto;
  font-size: 14px;
  padding: 6px 12px;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styles_loadButton__2VSEL {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border-width: 0px;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-left: auto;
  padding: 6px 12px;
}

.styles_loadButton__2VSEL:hover {
  cursor: pointer;
  background-color: rgb(237, 247, 255) !important;
}

.styles_activeButton__3vVRt {
  background-color: rgb(237, 247, 255) !important;
  cursor: default !important;
}

.styles_button__16mel {
  background-color: rgba(0, 0, 0, 0);
}

.styles_activeButton__3vVRt:hover,
.styles_button__16mel:hover {
  color: rgb(47, 57, 65);
}

.styles_button__16mel:active,
.styles_button__16mel:hover {
  background-color: rgba(31, 115, 183, 0.08) !important;
}

.styles_itemRow__1_Pue {
  display: flex;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid rgb(233, 235, 237);
}

.styles_itemData__3SNPc {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 0;
  position: relative;
}

.styles_itemData__3SNPc:first-child {
  width: 5%;
}
.styles_itemData__3SNPc:nth-child(2) {
  width: 7%;
}

.styles_itemData__3SNPc:last-child {
  display: -webkit-box;
  margin: 12px 12px 12px 0;
  overflow: hidden;
  padding: 0;
  white-space: pre-wrap;
  width: 88%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.styles_headerContainer__2e25- h3 {
  font-size: 14px;
  padding: 12px;
}

.styles_tableHeaderContainer__3c6P7 {
  display: flex;
  flex-direction: column;
  margin: 0 30px 30px 30px;
}

.styles_itemRow__3eQIR {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  text-align: left;
}

.styles_itemData__2qXG7 {
  border-bottom: 1px solid rgb(233, 235, 237);
  box-sizing: border-box;
  display: flex;
  padding: 12px;
  position: relative;
  height: 42px;
  width: 13%;
}

.styles_itemData__2qXG7:first-child {
  width: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_itemData__2qXG7:nth-child(3) {
  padding: 6px;
  width: 5%;
}

.styles_itemData__2qXG7:nth-child(4) {
  width: 10%;
}

.styles_itemData__2qXG7:nth-child(9) {
  justify-content: flex-end;
  width: 11%;
}

.styles_itemData__2qXG7:last-child {
  width: 5%;
  padding: 10px 15px;
  justify-content: center;
}

.styles_itemData__2qXG7 button {
  height: 1.5rem;
}

.styles_dotDotDotText__38u6z {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.styles_hint__zWoiR,
.styles_hintRightAligned__3x0dp {
  background: #383838;
  border-radius: 2px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-weight: normal;
  line-height: 16px;
  width: 600px;
  padding: 8px 10px;
  text-transform: none;
  white-space: pre-line;
  -webkit-user-select: none;
          user-select: none;
  z-index: 1000000;

  position: absolute;
  top: 36px;
  bottom: auto !important;
}
.styles_hint__zWoiR {
  left: 325px;
  right: auto !important;
}
.styles_hint__zWoiR.styles_large__1hQUp {
  width: 1000px;
}

.styles_hintRightAligned__3x0dp {
  left: auto !important;
  right: -325px;
}

.styles_hint__zWoiR,
.styles_hint__zWoiR li,
.styles_hint__zWoiR p,
.styles_hintRightAligned__3x0dp,
.styles_hintRightAligned__3x0dp li,
.styles_hintRightAligned__3x0dp p {
  font-size: 12px;
}
.styles_itemData__2qXG7 > .styles_hint__zWoiR,
.styles_itemData__2qXG7 > .styles_hintRightAligned__3x0dp {
  margin-bottom: -14px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, 0);
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.styles_itemData__2qXG7:hover > .styles_hint__zWoiR,
.styles_itemData__2qXG7:hover > .styles_hintRightAligned__3x0dp {
  opacity: 1;
  transform: translate(-50%, 8px);
}

.styles_intentContent__HPQGy {
  width: 100%;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styles_intentContent__HPQGy:hover {
  background-color: #f1f1f1;
}

.styles_optionsMenu__23yEG {
  position: absolute;
  left: 1.75rem;
  top: 1.25rem;
  width: 8rem;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.25rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2000000;
}

.styles_optionsMenu__23yEG div {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.styles_optionsMenu__23yEG div:hover {
  background-color: #f1f1f1;
}

.styles_dropDownAngleDownSVG__36xs5 {
  height: 0.5rem;
  width: auto;
  fill: #aaa;
}

.styles_wrapper__2WvO0 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 94vh;
  overflow-y: auto;
}

.styles_languageContainer__vY2ov {
  display: flex;
  margin: 0px 32px 32px 0;
  justify-content: flex-end;
}

body {
  background-size: 100%;
  color: #2f3941;
  display: flex;
  flex-direction: column;
  font-family: BlinkMacSystemFont, -apple-system, Roboto, Oxygen, Ubuntu,
    Cantarell, "Helvetica Neue", sans-serif;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.backButton {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 7px;
}

.backButton:hover .LeftArrowSVG {
  background-color: rgb(233, 235, 237);
  transition: all 0.3s;
}

.background1 {
  background-image: url(/static/media/1.807fb821.jpg);
}

.background2 {
  background-image: url(/static/media/2.5c65c4d8.jpg);
}

.background3 {
  background-image: url(/static/media/3.5c87cbfc.jpg);
}

.background4 {
  background-image: url(/static/media/4.007c111a.jpg);
}

.background5 {
  background-image: url(/static/media/5.0e410bca.jpg);
}

.background6 {
  background-image: url(/static/media/6.68905a9d.jpg);
}

.buttonPrimary,
.buttonTertiary {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
  margin: 0;
}

.buttonPrimary {
  background-color: #2595ad;
  box-shadow: 0 2px 1px 0 rgb(0 0 0 / 20%);
  color: #fff;
  padding: 0 25px;
}

.buttonPrimary:hover {
  background-color: #277e88;
  transition: all 0.3s;
}

.buttonTertiary {
  padding: 0 10px;
  transition: border-color 0.25s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s,
    background-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s,
    z-index 0.25s ease-in-out 0s;
}

.buttonTertiary:hover {
  background-color: rgba(104, 115, 125, 0.15);
  color: rgb(73, 84, 92);
  transition: all 0.3s;
}

.chatButtons {
  padding: 30px 30px 30px 0;
}

.closeButton {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  margin: 0;
  outline: none;
  padding: 4px;
}

.closeButton:hover .closeIconSvg {
  background-color: rgb(233, 235, 237);
  transition: all 0.3s;
}

.closeIconSvg {
  border-radius: 50%;
  box-sizing: content-box;
  cursor: pointer;
  fill: #454f63;
  height: 14px;
  outline: none;
  padding: 4px;
  stroke: #454f63;
  width: 14px;
}

.dontShrink {
  flex-shrink: 0;
}

h1 {
  font-size: 44px;
  line-height: 46px;
}

h2 {
  font-size: 32px;
  line-height: 34px;
  margin-top: 12px;
}

h3 {
  font-size: 24px;
  line-height: 26px;
}

p {
  font-size: 17px;
  line-height: 19px;
}

a {
  color: #277e88;
}

textarea {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  resize: none;
}

.badge {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  height: 22px;
  line-height: 22px;
  min-width: 22px;
  padding: 0 2px;
  text-align: center;

  font-size: 11px;
  font-weight: 600;
}

.small-badge {
  width: 16px;
  height: 16px;
  padding: 0;
  font-size: 10px;
}

.gray-badge {
  background-color: #d7dcde;
}

.red-badge {
  background-color: #eb4034;
  color: white;
}

.view.active .gray-badge {
  background-color: #666;
  color: #fff;
}

.badge:hover .tooltip {
  visibility: visible;
}

.tooltip {
  visibility: hidden;

  background-color: #9c9c9c;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  white-space: nowrap;
  padding: 0.2rem 0.7rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  position: absolute;
  top: -1.8rem;

  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexGrowOne {
  flex-grow: 1;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.hiddenOverflow {
  overflow: hidden;
}

.primaryGraphicElement1 {
  background-color: #2595ad;
}

.primaryGraphicElement2 {
  background-color: #277e88;
}

.primaryGraphicElement3 {
  background-color: #115672;
}

.subHeading1 {
  color: #277e88;
}

.subHeading2 {
  color: #115672;
}

.secondaryGraphicElement1 {
  background-color: #e6fdff;
}

.secondaryGraphicElement2 {
  background-color: #f6f9fc;
}

.loginContainer {
  background-color: rgb(255 255 255 / 95%);
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  margin: auto;
  padding: 20px;
  text-align: center;
  width: 360px;
}

.form-control {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 8%);
  color: #555;
  font-size: 14px;
  height: 30px;
  line-height: 1.42857143;
  padding: 6px 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.tickets-search-input {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 8%);
  box-sizing: content-box;
  color: #555;
  font-size: 16px;
  height: 22px;

  line-height: 1.42857143;
  padding: 6px 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.tickets-search-container {
  display: flex;
  flex-direction: column;

  justify-content: center;
  max-width: 300px;
  width: 100%;
  margin: 10px 30px;
}

.headerContainer {
  background-color: white;
  border-bottom: solid 1px #e9ebed;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.loginButton {
  background-image: none;
  line-height: 2.5;
  margin-top: 48px;
  padding: 6px 12px;
  text-align: center;
  touch-action: manipulation;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}
.versionTextWrapper {
  align-items: center;
  color: #ccc;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}
.versionTextWrapper p {
  font-size: 10px;
  margin: auto;
}
.loginInputLabel {
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 30px;
  text-align: left;
}

.rowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.rowSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alignItemsCenter {
  align-items: center;
}

.shakeElement {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}

.ticketCategoryBox {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 8px 12px;
}

.menu li button:hover,
.menu-trigger:hover,
.ticketCategoryBox:hover {
  background-color: rgb(237, 247, 255) !important;
  cursor: pointer;
  transition: all 0.3s;
}

.ticketsContainer,
.ticketContainer {
  background-color: white;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  display: flex;
}

.ticketContainer {
  overflow: hidden;
}
.ticketHeader {
  background-color: #f8f9f9;
  border-bottom: 1px #d8dcde solid;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  height: 49px;
  padding: 10px;
}
.tickerHeaderButton {
  background-color: #e9ebed;
  border: 1px solid #d8dcde;
  border-radius: 4px;
  box-sizing: border-box;
  height: 28px;
  padding: 4px 12px;
  display: flex;
}

.yourTicketsSection,
.aiPage {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.yourTicketsSection {
  height: calc(100vh - 3.5rem);
}

.chatSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.chatMessageContainer {
  margin-bottom: 6px;
  margin-top: 6px;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 30px;
  max-height: 38rem;
}

.chatBubble {
  background-color: #f1f0f0;
  color: rgba(0, 0, 0, 1);
  float: left;
  font-size: 14px;
  border-radius: 1.3em;
  margin-bottom: 1px;
  max-width: 80%;
  padding: 6px 12px;
  word-break: break-word;
  white-space: pre-line;
}

.chatBubbleSent {
  background-color: #2595ad;
  color: #fff;
}

.chatAuthor {
  margin: 0px 0px 2px 0px;
}

.chatAuthorCurrentUser {
  margin: 0px 10px 2px 0px;
  text-align: right;
}

.chatInputContainer {
  border-top: solid 1px #e0e0e0;
  width: 100%;
  display: flex;
}

.chatInput {
  background: #fff;
  border-radius: 3px;
  border: 1px solid silver;
  box-shadow: inset 0 2px 4px 0 hsl(0deg 0% 0% / 8%);
  box-sizing: border-box;
  margin: 30px;
  padding: 8px;
  max-height: 12rem;
}

@media (max-width: 768px) {
  .chatInputContainer {
    flex-direction: column;
  }

  .chatInput {
    margin: 16px 30px 0 30px;
  }

  .chatButtons {
    padding: 16px 30px 16px 0;
  }
}

.dropDownAngleDownSVG {
  height: 16px;
  width: auto;
  margin-left: auto;
}

.flexJustCenter {
  display: flex;
  justify-content: center;
}

.hide {
  display: none;
}

.rightBorder {
  border-right: solid 1px #e0e0e0;
}
.leftBorder {
  border-left: solid 1px #e0e0e0;
}
.logoutButton {
  align-items: center;
  border-radius: 4px;
  border-width: 0px;
  display: flex;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  margin: auto 20px;
  padding: 0 10px;
}

.menu-container {
  margin-block-end: 3em;
  position: relative;
}

.menu {
  background: #ffffff;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 50px;
  right: 0;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-8px);
  transition: opacity 150ms ease, transform 150ms ease, visibility 150ms;
  width: 100%;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.menu.scrollable {
  max-height: 275px;
  overflow-y: auto;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-top: 1px solid #dddddd;
}

.menu li button {
  align-items: center;
  background-color: #fff;
  border: none;
  color: #333333;
  display: flex;
  padding: 17px 12px;
  text-decoration: none;
  width: 100%;
}

.menu-trigger,
.user-summary {
  align-items: center;
  border: none;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  vertical-align: middle;
}

.menu-trigger {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  cursor: pointer;
  height: 50px;
  padding: 0px 12px;
  transition: box-shadow 0.4s ease;
}

.menu-trigger span,
.user-summary span {
  font-size: 14px;
}

.menu button:last-of-type {
  border-radius: 4px;
}

.menu li img,
.menu-trigger img,
.user-summary img {
  border-radius: 90px;
  height: 32px;
  margin-right: 12px;
  width: 32px;
}

.sideBar {
  width: 270px;
  min-width: 270px;
  text-align: left;
  padding: 30px;
}

.subtext {
  color: #637282;
  font-size: 12px !important;
  margin-top: auto;
}

.ticketSection {
  background-color: #f8f9f9;
  box-sizing: content-box;
  padding: 30px;
  text-align: left;
  width: 270px;
}

.tableHeaderContainer {
  align-items: center;
  border-bottom: 1px solid rgb(216, 220, 222);
  display: flex;
  justify-content: center;
  margin: 30px 30px 0px 30px;
}

.tableListWrapper {
  margin: 0px 30px 30px 30px;
}

.yourTicketSectionHeader {
  margin: 30px;
}

.ticketListItemRequester,
.yourTicketsSectionRequesterContainer {
  width: 35%;
}

.ticketListItemRequester {
  flex-direction: column;
}

.ticketListItemSubject,
.yourTicketsSectionSubjectContainer {
  width: 20%;
}

.ticketListAgent,
.yourTicketsSectionAgentNameContainer {
  width: 30%;
}

.ticketListAgent,
.ticketListItemStatus,
.ticketListItemUpdatedAt,
.ticketListRead {
  display: flex;
  align-items: center;
}

.ticketListItemStatus,
.yourTicketsSectionStatusContainer {
  width: 10%;
}
.ticketListRead,
.yourTicketsSectionReadContainer {
  width: 10%;
}
.ticketListItemUpdatedAt,
.yourTicketsSectionUpdatedAtContainer {
  width: 15%;
}

.yourTicketsSectionContentContainerHeader {
  display: flex;
  font-size: 14px;
  padding: 8px 15px;
}

.noMargin {
  margin: 0px !important;
}

.paginationContent,
.paginationContentHolder {
  box-sizing: content-box;
  display: flex;
  flex-flow: wrap;
  margin-bottom: 0;
  margin-top: 0;
  width: -webkit-max-content;
  width: max-content;
}

.ticketsLoaderBox {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 450px;
}

.loader {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2;
}

.loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticketLoaderBox {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

span.paginationContent {
  border: 1px solid rgb(216, 220, 222);
  box-shadow: none;
  color: #2f3941;
  display: block;
  font-size: 15px;
  line-height: 34px;
  min-width: 35px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.paginationContentHolder > div:not(:first-child) > span.paginationContent {
  border-left-width: 0px;
}

span.paginationContent:hover {
  background-color: #f4f4f4;
}

.activePaginationItem,
.activePaginationItem:hover,
.activePaginationItem span.paginationContent:hover {
  background-color: rgb(248, 249, 249);
  border-color: #ced4da;
  cursor: default;
}

.sectionHeader {
  margin: 30px;
  text-align: left;
}

.yourTicketSectionSubHeader {
  color: #68737d;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  line-height: 18px;
}

.ticketList {
  display: flex;
  flex-direction: column;
  margin: 0px 30px 30px 30px;
}

.ticketListItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  text-align: left;
}

.ticketListItem:hover {
  background-color: rgb(237, 247, 255);
  transition: all 0.3s;
}

.ticketListItemRequester,
.ticketListItemSubject,
.ticketListAgent,
.ticketListItemStatus,
.ticketListItemUpdatedAt,
.ticketListRead {
  border-bottom: 1px solid rgb(233, 235, 237);
  box-sizing: border-box;
  display: flex;
  padding: 12px 15px;
  height: 60px;
}

.ticketsSortBtn {
  display: flex;
  padding: 6px;
  margin-left: 10px;

  border: 1px solid rgb(233, 235, 237);
  background-color: transparent;

  cursor: pointer;
}

.ticketsSortBtn:hover {
  background-color: rgb(233, 235, 237);
}

.ticketsSortBtn svg {
  height: 12px;
}

.stretch {
  width: 100%;
}

.openTicket {
  border-bottom: solid 1px #e9ebed;
  border-right: 1px solid rgb(233, 235, 237);
  box-sizing: border-box;
  cursor: pointer;
  flex: 1 1;
  font-size: 14px;
  height: 50px;
  max-width: 240px;
  padding: 9px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.openTicket.active {
  background-color: rgb(248, 249, 249);
  border-bottom: 1px solid rgb(248, 249, 249);
  cursor: default;
  font-weight: 600;
}

.openTicket.active:hover {
  background-color: rgb(248, 249, 249);
}

.openTicket:hover {
  background-color: rgb(248, 249, 249);
  transition: all 0.3s;
}

.openTicketsContainer {
  align-self: flex-end;
  border-left: 1px solid rgb(233, 235, 237);
  display: flex;
  flex-direction: row;
  height: 49px;
}

.openTicketId {
  font-size: 12px;
}

.openTicketSubject {
  font-weight: 600;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.LeftArrowSVG {
  border-radius: 50%;
  box-sizing: content-box;
  fill: #454f63 !important;
  max-height: 20px;
  max-width: 20px;
  padding: 8px;
  stroke: #454f63;
}

.SVGHover:hover {
  fill: #2595ad !important;
  stroke: #2595ad;
  transition: all 0.3s;
}

.invisibleSVG,
.invisibleSVG:hover {
  background-color: transparent !important;
  fill: transparent !important;
  stroke: transparent;
  cursor: default;
}

.invisibleCloseIconSVG:hover {
  fill: transparent !important;
  stroke: transparent;
  cursor: default;
}

.view {
  border-radius: 4px;
  box-sizing: border-box;
  color: rgb(47, 57, 65);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  padding: 8px 16px;
  transition: box-shadow 0.1s ease-in-out 0s;
}

.view.active {
  background-color: rgb(206, 226, 242);
  cursor: default;
  font-weight: 600;
}

.view:hover {
  background-color: rgba(31, 115, 183, 0.08);
  transition: all 0.3s;
}

.view.active:hover {
  background-color: rgb(206, 226, 242);
}

/* DONT TOUCH! Causes non-scrollable overflow if removed */
.fixedWindowHeight {
  height: 100vh;
}

.user-summary {
  cursor: default;
}
.ticketStatus.closed {
  background-color: #999;
  color: white;
}
.readText,
.ticketStatus.open {
  background-color: #fff;
  border: 1px solid #eee;
}

.readText,
.ticketStatus,
.unreadText {
  border-radius: 4px;
  font-size: 9px;
  font-weight: 600;
  margin: auto 5px auto 0;
  padding: 2px 3px;
  text-transform: uppercase;
}

.statusWrapper {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unreadText {
  background-color: #eb4034;
  border: 1px solid transparent;
  color: white;
}

.messageTimeStamp {
  color: #969595;
  font-size: 12px;
}

.booking-listitem-check-mark {
  width: 30px;
}

.check-mark-gray-foreground .check-mark-foreground {
  fill: #b3b3b3;
}

.relative-position {
  position: relative;
}

.check-mark-base {
  cursor: pointer;
  height: 24px;
  pointer-events: none;
  position: absolute;
  width: 24px;
}
.check-mark-base.enabled {
  pointer-events: auto;
}

.parent:hover .check-mark-base.enabled .check-mark-foreground {
  opacity: 1;
}

.parent:hover .check-mark-base.enabled .check-mark-foreground:hover {
  fill-opacity: 1;
}

.check-mark-checked .check-mark-base {
  cursor: default;
}

.check-mark-background,
.check-mark-foreground {
  fill: #fff;
  position: absolute;
  left: 0;
  top: 0;
}
.check-mark-foreground {
  fill-opacity: 0.54;
  opacity: 0;
  transition: all 0.135s cubic-bezier(0, 0, 0.2, 1);
  transition-property: opacity, fill-opacity;
}
.check-mark-checked .check-mark-foreground,
.check-mark-to-left-holder:hover
  .check-mark-to-left
  .check-mark-foreground:hover {
  fill-opacity: 1;
  opacity: 1;
}
.check-mark-checked .blue-when-selected {
  fill: #1a73e8;
}
.check-mark-background {
  display: none;
}

.check-mark-checked .white-when-selected {
  fill: #fff;
}
.check-mark-checked .check-mark-background {
  display: block;
}

.check-mark-base.enabled .check-mark-foreground:hover {
  fill-opacity: 1;
}

.no-margin {
  margin: 0;
}

