.wrapper {
  margin: 0 30px;
  display: flex;
  gap: 5rem;
}

.buttonGroup {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.buttonGroup > .activeButton:not(:last-child),
.buttonGroup > .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonGroup > .activeButton:not(:first-child),
.buttonGroup > .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.activeButton,
.button {
  border: solid 1px rgb(233, 235, 237) !important;
  border-radius: 4px;
  color: rgb(47, 57, 65) !important;
  flex: 1 1 auto;
  font-size: 14px;
  padding: 6px 12px;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.loadButton {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border-width: 0px;
  box-shadow: 0 2px 2px 0 hsl(0deg 0% 0% / 20%);
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-left: auto;
  padding: 6px 12px;
}

.loadButton:hover {
  cursor: pointer;
  background-color: rgb(237, 247, 255) !important;
}

.activeButton {
  background-color: rgb(237, 247, 255) !important;
  cursor: default !important;
}

.button {
  background-color: rgba(0, 0, 0, 0);
}

.activeButton:hover,
.button:hover {
  color: rgb(47, 57, 65);
}

.button:active,
.button:hover {
  background-color: rgba(31, 115, 183, 0.08) !important;
}
