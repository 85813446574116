.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 94vh;
  overflow-y: auto;
}

.languageContainer {
  display: flex;
  margin: 0px 32px 32px 0;
  justify-content: flex-end;
}
