.itemRow {
  display: flex;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid rgb(233, 235, 237);
}

.itemData {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 12px 0;
  position: relative;
}

.itemData:first-child {
  width: 5%;
}
.itemData:nth-child(2) {
  width: 7%;
}

.itemData:last-child {
  display: -webkit-box;
  margin: 12px 12px 12px 0;
  overflow: hidden;
  padding: 0;
  white-space: pre-wrap;
  width: 88%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}
