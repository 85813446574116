.headerContainer h3 {
  font-size: 14px;
  padding: 12px;
}

.tableHeaderContainer {
  display: flex;
  flex-direction: column;
  margin: 0 30px 30px 30px;
}

.itemRow {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  text-align: left;
}

.itemData {
  border-bottom: 1px solid rgb(233, 235, 237);
  box-sizing: border-box;
  display: flex;
  padding: 12px;
  position: relative;
  height: 42px;
  width: 13%;
}

.itemData:first-child {
  width: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemData:nth-child(3) {
  padding: 6px;
  width: 5%;
}

.itemData:nth-child(4) {
  width: 10%;
}

.itemData:nth-child(9) {
  justify-content: flex-end;
  width: 11%;
}

.itemData:last-child {
  width: 5%;
  padding: 10px 15px;
  justify-content: center;
}

.itemData button {
  height: 1.5rem;
}

.dotDotDotText {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hint,
.hintRightAligned {
  background: #383838;
  border-radius: 2px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-weight: normal;
  line-height: 16px;
  width: 600px;
  padding: 8px 10px;
  text-transform: none;
  white-space: pre-line;
  user-select: none;
  z-index: 1000000;

  position: absolute;
  top: 36px;
  bottom: auto !important;
}
.hint {
  left: 325px;
  right: auto !important;
}
.hint.large {
  width: 1000px;
}

.hintRightAligned {
  left: auto !important;
  right: -325px;
}

.hint,
.hint li,
.hint p,
.hintRightAligned,
.hintRightAligned li,
.hintRightAligned p {
  font-size: 12px;
}
.itemData > .hint,
.itemData > .hintRightAligned {
  margin-bottom: -14px;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, 0);
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.itemData:hover > .hint,
.itemData:hover > .hintRightAligned {
  opacity: 1;
  transform: translate(-50%, 8px);
}

.intentContent {
  width: 100%;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intentContent:hover {
  background-color: #f1f1f1;
}

.optionsMenu {
  position: absolute;
  left: 1.75rem;
  top: 1.25rem;
  width: 8rem;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.25rem;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 2000000;
}

.optionsMenu div {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.optionsMenu div:hover {
  background-color: #f1f1f1;
}

.dropDownAngleDownSVG {
  height: 0.5rem;
  width: auto;
  fill: #aaa;
}
